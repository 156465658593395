<template>
  <WGContainer>
    <div class="wg-footer-row" :class="{'wg-footer-copyright': paymentText}">
      <p v-if="paymentText" class="wg-footer-text">
        {{ paymentText }} Phone number:
        <a class="wg-footer-text" href="tel:+420775931202">+420775931202</a>
      </p>
      <WGCopyright
        class="text-center wg-footer-text"
        :textPrepend="currentProjectLink"
        :dateFrom="2008"
        :textAppend="$t('global.siteRights')"
      />
    </div>
  </WGContainer>
</template>

<script>
import WGContainer from '@/components/UI/WGContainers/WGContainer.vue'
import WGCopyright from '@/components/UI/WGCopyright/WGCopyright'
import { computed, inject } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'WFFooterCopyright',
  components: {
    WGContainer,
    WGCopyright
  },
  setup () {
    const store = useStore()
    const { currentProjectLink } = inject('$projectLinks')

    const paymentText = computed(() => {
      return store.getters.getSiteSettings?.settings?.payments_description?.replaceAll('Website/URL is operated by', '')
    })

    return {
      currentProjectLink,
      paymentText
    }
  }
}
</script>

<style lang="scss">
.wg-footer-copyright {
  align-items: center;

  .wg-footer-text {
    width: 50%;
    @media only screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
</style>
