<template>
  <main class="page-main">
    <WGContainer is-clear-offsets>
      <WGBreadCrumbs :breadCrumbsList="breadCrumbsList" />
    </WGContainer>
    <WGContainer class="bg-white">
      <div class="page-service">
        <div class="page-service_content">
          <h1 class="page-service_headline page-service_title">{{ $t('services.title', { website: currentProjectLink }) }}</h1>
          <p class="page-service_description">
            {{ $t('services.provided', { website: currentProjectLink }) }}
            <br />
            <br />
            {{ $t('services.provided2',  { website: currentProjectLink }) }}
          </p>
          <h2 class="page-service_headline page-service_rate-title">{{ $t('services.rates') }}</h2>
          <div class="page-service_table">
            <WGDescriptionFields class="page-service_price" :elements="prices">
              <template #key="{ item }">
                <div>
                  <WGList class="page-service_price-list" :list="setServiceByPrice(item)">
                    <template #item="{ item }">
                      <p class="page-service_price-list_service" v-html="splitText($t(`services.${item}`))"></p>
                    </template>
                  </WGList>
                </div>
              </template>
              <template #item="{ item }">
                <p class="page-service_price-list_price">
                  {{ +item === 0 ? $t('global.free').toUpperCase() : `${item} ${getCurrency(item)}` }}
                </p>
              </template>
            </WGDescriptionFields>
          </div>
          <div class="page-service_wrapper">
            <div class="page-service_banner">
              <div class="page-service_banner-title">
                <p class="page-service_banner-title_item">{{ $t('services.special') }}</p>
                <p class="page-service_banner-title_item">{{ $t('services.offer') }}</p>
              </div>
              <div class="page-service_banner-description">
                <p><strong>{{ $t('services.bannerBoldText1') }}</strong>&nbsp;{{ $t('services.bannerText1') }}
                <br />
                <strong>{{ $t('services.bannerBoldText2') }}</strong>&nbsp;{{ $t('services.bannerText2') }}</p>
              </div>
            </div>
            <WGButton
              variant="warning"
              size="sm"
              class="text-uppercase page-service_banner-button"
              @click="handleAuth"
            >
              {{ $t('services.buyBtn') }}
            </WGButton>
            <div class="page-service_banner-border"></div>
            <img
              class="page-service_banner_image"
              src="@/assets/images/services/banner-girls.webp"
              alt="girls banner"
            />
          </div>
          <p class="page-service_footer">
            Terms and conditions are approved.
            <span v-if="paymentText">{{ paymentText }}</span>
            {{ $t('services.forQuestions') }}
            <a class="page-service_footer-terms" href="mailto:support@victoriyaclub.com">
              support@victoriyaclub.com
            </a>
          </p>
        </div>
        <div class="page-service_photo-wrapper">
          <div @click="handleAuth">
            <img
              class="page-service_photo-wrapper-img"
              src="@/assets/images/services/girl-photo.webp"
              alt="girl"
              draggable="false"
            >
            <WGButton
              variant="warning"
              size="sm"
              class="text-uppercase page-service_photo-wrapper_btn"
            >
              {{ $t('global.createAccount') }}
            </WGButton>
          </div>
          <div class="page-service_card-wrapper">
            <WGSwiper
              v-if="allGirlsList"
              :swiperList="allGirlsList.list"
              :autoHeight="true"
              :navigation="{ nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }"
              :slidesPerView="1"
              :autoplay="{
                delay: 5000,
                disableOnInteraction: true,
                pauseOnMouseEnter: true
              }"
              :loop="true"
              @swiper="onSwiperGirls"
            >
              <template #item="{item}">
                <WGCardModel
                  class="page-service_card-model"
                  :user="item"
                  :urlStorage="siteSettings?.settings?.url_storage || null"
                />
              </template>
            </WGSwiper>
            <div class="swiper-button-prev">
              <svg class="swiper-button-prev_icon" width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 1L1.41421 6.58579C0.633163 7.36684 0.633165 8.63316 1.41421 9.41421L7 15" stroke="#C8D3DC" stroke-width="1.5" fill="none" stroke-linecap="round"/>
              </svg>
            </div>
            <div class="swiper-button-next">
              <svg class="swiper-button-next_icon" width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 15L6.58579 9.41421C7.36684 8.63316 7.36683 7.36683 6.58579 6.58579L0.999999 1" stroke="#C8D3DC" stroke-width="1.5" fill="none" stroke-linecap="round"/>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </WGContainer>
  </main>
</template>

<script>
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { computed, inject, ref } from 'vue'

import WGList from '@/components/UI/WGLists/WGList.vue'
import WGButton from '@/components/UI/WGButtons/WGButton.vue'
import WGContainer from '@/components/UI/WGContainers/WGContainer.vue'
import WGBreadCrumbs from '@/components/UI/WGBreadCrumbs/WGBreadCrumbs.vue'
import WGDescriptionFields from '@/components/UI/WGDescriptionFields/WGDescriptionFields.vue'
import WGCardModel from '@/components/UI/WGCards/WGCardModel'
import WGSwiper from '@/components/WGSliders/WGSwiper'

export default {
  name: 'PageService',
  components: {
    WGSwiper,
    WGCardModel,
    WGButton,
    WGList,
    WGDescriptionFields,
    WGBreadCrumbs,
    WGContainer
  },
  setup () {
    const store = useStore()
    const sliderGirls = ref(null)
    const { currentProjectLink } = inject('$projectLinks')
    const { t } = useI18n()
    const getServerData = () => {
      store.dispatch('apiGetGirlsList', { limit: 5 })
    }
    getServerData()
    const siteSettings = computed(() => {
      return store.getters.getSiteSettings
    })
    const allGirlsList = computed(() => {
      return store.getters.getGirlsList(('all'))
    })
    const onSwiperGirls = swiper => {
      sliderGirls.value = swiper
    }
    const updateSliderGirls = () => {
      setTimeout(() => {
        sliderGirls.value.updateAutoHeight(true)
        sliderGirls.value.update(true)
      }, 50)
    }

    document.title = 'Online Dating Services - Find Your Loved One 🫶| VictoriyaClub'
    const metaDescription = document.querySelector('meta[name="description"]')
    const newDescription = 'Sending messages, video chat, personal information, and real girls - these and even more services you will find on our website😍😘! Start online dating today, do not waste your time 🥳!'
    if (metaDescription) {
      metaDescription.setAttribute('content', newDescription)
    } else {
      const newMetaTag = document.createElement('meta')
      newMetaTag.setAttribute('name', 'description')
      newMetaTag.setAttribute('content', newDescription)
      document.head.appendChild(newMetaTag)
    }

    store.dispatch('initialize')
    store.dispatch('apiGetServicesData')

    const paymentText = computed(() => {
      return store.getters.getSiteSettings?.settings?.payments_description.replaceAll('Website/URL', currentProjectLink)
    })

    const roundUp = stringNumber => {
      const number = parseFloat(stringNumber)

      return Math.ceil(number)
    }

    const getCurrency = (price) => {
      if (price > 1) {
        return t('services.credits')
      }

      return t('services.credit')
    }

    const servicesList = computed(() => {
      return store.state.pageText.services
    })

    const splitText = text => {
      return text.split('\n').join('<br />')
    }

    const getPriceData = computed(() => [
      ...Object.values(servicesList.value).map(value =>
        roundUp(value).toString()
      )
    ])

    const handleAuth = () => {
      store.commit('addModal', 'VictoriaModalAuth')
    }

    const prices = computed(() => [
      ...new Set(getPriceData.value)
    ]?.sort((prev, next) => prev - next))

    const setServiceByPrice = price => {
      return Object.keys(servicesList.value)
        .map(service => {
          if (roundUp(servicesList.value[service]) === +price) {
            return service
          }

          return null
        })
        .filter(item => item)
    }

    const breadCrumbsList = computed(() => [
      { label: 'breadCrumbs.home', url: '/' },
      { label: 'Services', isNotI18n: true }
    ])

    return {
      breadCrumbsList,
      siteSettings,
      allGirlsList,
      getPriceData,
      prices,
      currentProjectLink,
      paymentText,
      onSwiperGirls,
      updateSliderGirls,
      splitText,
      handleAuth,
      getServerData,
      getCurrency,
      setServiceByPrice
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/common/UI/service-page.scss';
</style>
